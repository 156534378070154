body {
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

.LeagueTitle {
  padding-top: 20px;
  text-align: center;
}

.League:first-child {
  padding-bottom: 40px;
  border-bottom: 2px dashed black;
}

.Matchup {
  display: flex;
  flex-direction: row;
}

.Matchup h2 {
  text-align: center;
  color: yellow;
}

.TeamName {
  background-color: black;
  display: flex;
  justify-content: center;
  height: 60px;
}

.Roster {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 400px;
  width: 250px;
}

.Player {
  padding: 15px;
  width: 100%;
  overflow: hidden;
}

.Player:nth-child(even) {
  background-color: #cacaca;
}
.Player:nth-child(odd) {
  background-color: #e2e2e2;
}

.Player .Name {
  font-size: 10pt;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Player .Score {
  display: inline-block;
  color: #000;
  font-size: 12pt;
}

.Player .Projection {
  display: inline-block;
  position: relative;
  left: 10px;
  color: #414141;
  font-size: 9pt;
}

.Total {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 40px;
  padding-bottom: 20px;
}

.Total .Score {
  color: yellow;
  font-size: 18pt;
  font-family: "Orbitron", sans-serif;
  font-weight: bold;
}

.Total .Projection {
  padding-left: 10px;
  color: yellow;
  font-size: 9pt;
}
